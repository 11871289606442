.section1-container {
  height: 1100px;
  width: 100%;
  background: #030400;
  /* url("./components/redesignComponents/images/poster.webp") 100px -100px /
      auto no-repeat, */
  /* background: url("./components/redesignComponents/images/rocket.webp") 0 -100px /
      auto no-repeat,
    #030400; */
}

.section1-content {
  position: relative;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  /* background: #000; */
  padding-top: 200px;
  display: grid;
  /* justify-content: space-between; */
  grid-template-columns: 1fr 3fr;
  /* grid-template-rows: 1fr; */
}

.section1-content .heading {
  z-index: 5;
  /* position: absolute;
  left: 0; */
}
.section1-content .banner {
  position: relative;
}
.section1-content .banner img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  /* position: absolute; */
  /* top: 0; */
  transform: scale(1.1) translateY(-190px);
}
.section1-content p {
  color: #fff;
  font-size: 2.8rem;
  font-weight: 200;
  padding-left: 10px;
  margin-top: 70px;
}
.section1-content img {
  height: 66.73px;
}

.section2-container {
  width: 100%;
  min-height: 600px;
  background-color: #030400;
  padding-bottom: 25px;
}
.section2-content {
  width: 95%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 40px;
  color: #fff;
  /* align-items: center; */
}
.section2-content div {
  width: calc(40%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  gap: 15px;
  position: relative;
}
.border-after::after {
  content: "";
  position: absolute;
  right: -3%;
  height: 100%;
  width: 1px;
  background-color: #818181;
}
.section2-content div p {
  font-size: 16px;
  font-weight: 100;
}
.section2-content div img {
  width: 100%;
}
.section2-content .apply-btn {
  background-color: #1e6424;
  color: #fff;
  padding: 15px 35px;
  border-radius: 5px;
  align-self: center;
  font-weight: 700;
}

.section2-content .section2-heading {
  font-family: "Libre Bodoni";
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 15px;
}
.features-section-container {
  width: 100%;
  min-height: 60vh;
  background: url("./components//redesignComponents/images/Vector.svg")
      center/cover no-repeat,
    #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 30px 0;
}
.features-card {
  width: 300px;
  height: 400px;
  padding: 10px;
  background-color: #e2e2e2;
  border: #bbbbbb 1px solid;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.099);
  border-radius: 8px;
}
.features-card p {
  font-size: 14px;
  font-weight: 100;
  margin-top: 25px;
  padding: 0 5px;
}
.features-card span {
  color: #000;
  font-weight: 500;
}
.features-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
}
.features-section h1 {
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
  font-weight: 700;
}
.news-section {
  display: grid;
}
.news-section-container {
  width: 100%;
  min-height: 60vh;
  background: url("./components//redesignComponents/images/Vector.svg")
      center/cover no-repeat,
    #030400;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  padding: 30px 0;
  gap: 30px;
}
.news-card {
  width: 300px;
  height: 400px;
  padding: 10px;
  background-color: #223327;
  /* border: #bbbbbb 1px solid; */
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.099);
  color: #fff;
  border-radius: 8px;
  position: relative;
}
.news-section-container > h1 {
  color: #fff;
}
.news-card p {
  font-size: 14px;
  font-weight: 100;
  margin-top: 25px;
  padding: 0 5px;
}
.news-card span {
  /* color: #000; */
  font-weight: 500;
}

.more-news {
  border: 1px solid #fff;
  color: #fff;
  padding: 10px 25px;
  justify-self: center;
  margin-top: 15px;
  border-radius: 5px;
}
.news-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
}
.news-section h1 {
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
  font-weight: 700;
  color: #fff;
}
.news-section .news-btn {
  display: block;
  background-color: #ffffff;
  color: #000;
  border-radius: 8px;
  padding: 10px 25px;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.blog-section {
  background: url("./components//redesignComponents/images/Vector.svg")
      center/cover no-repeat,
    #e2e2e2;
  /* background: #f1f1f1; */
  padding: 60px 40px;
}
.blog-section .title--sec h3 {
  font-size: 38px;
  color: #2b292a;
  font-weight: bold;
  line-height: 60px;
}
.blog-section .title--sec h4 {
  font-size: 20px;
  color: #2b292a;
  font-weight: bold;
}
.slider--blog {
  margin-top: 60px;
  padding: 0 20px !important;
}
.slider--blog .swiper-slide {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.slider--blog .img--news {
  width: 248px;
  height: 248px;
  border-radius: 50%;
  box-shadow: 1px 11px 20px 2px rgb(0 0 0 / 55%);
  cursor: pointer;
}
.slider--blog .img--news img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 1px 11px 20px 2px rgb(0 0 0 / 55%);
}
.slider--blog .info--news {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.slider--blog .info--news .date {
  font-size: 18px;
  font-weight: bold;
  color: #848384;
}
.slider--blog .info--news .title--news {
  font-size: 20px;
  font-weight: bold;
  color: #2b292a;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.slider--blog .swiper-button-prev,
.slider--blog .swiper-button-next {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #fff;
  opacity: 1;
  cursor: pointer;
  top: 37%;
}
.slider--blog .swiper-button-prev {
  left: 50px;
}
.slider--blog .swiper-button-next {
  right: 50px;
}
.slider--blog .swiper-button-prev::after,
.slider--blog .swiper-button-next::after {
  font-size: 15px;
  color: #466088;
  font-weight: bold;
}
.challenge {
  display: none;
  width: 200px;
  height: 200px;
  object-fit: cover;
  position: fixed;
  bottom: 20px;
  left: 20px;
  /* bottom: ; */
  z-index: 10;
}

@media only screen and (max-width: 1100px) {
  /*Small smartphones [325px -> 425px]*/
  .section1-content .heading {
    position: absolute;
    left: 0;
    top: 100px;
  }
  .section1-content {
    grid-template-columns: 1fr;
  }
  .section1-content .banner {
    width: 100%;
  }
  .section1-content .banner img {
    clip-path: polygon(0 20%, 100% 20%, 100% 100%, 0% 100%);
    transform: scale(1.1) translateY(-300px);
  }
  .section1-content p {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 700px) {
  .border-after::after {
    display: none;
  }
  .section2-content {
    flex-direction: column;
  }
  .section2-content div {
    width: 90%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 600px) {
  .section1-container {
    overflow: hidden;
    height: 600px;
  }
  .section2-why {
    /* display: flex; */
    flex-direction: column;
  }
  .section2-why img {
    align-self: flex-end;
    order: 1;
  }
  .section1-content .heading {
    transform: scale(0.6);
    left: -65px;
  }
  .section1-content .banner img {
    transform: scale(1.5) translateY(-100px);
  }
  .section1-content {
    width: 95%;
  }
  .section1-content p {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 450px) {
  .section1-content .banner img {
    /* transform: scale(0) translateY(-300px); */
  }
  .section1-content .heading {
    transform: scale(0.5);
    /* left: -5px; */
  }
  .challenge {
    width: 150px;
    height: 150px;
    left: unset;
    right: 20px;
  }
}
