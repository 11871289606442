.main-poster {
  position: relative;
  padding-bottom: 100px;
}
.main-poster::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 437px;
  bottom: 0;
  right: 0;
  background: url(../../assets/images/grouppic.webp);
  background-size: 100% 100%;
  z-index: 1;
}
.main-poster::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100px;
  background: #000;
  bottom: 0;
  left: 0;
}
.main-poster .img-poster {
  width: 100%;
  height: auto;
  position: relative;
}
.main-poster .img-poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .main-poster .img-poster::before {
  position: absolute;
  content: url(../../assets/images/Dot.webp);
  top: 151px;
  right: 0;
  width: 76px;
  height: 236px;
}
.main-poster .img-poster::after {
  position: absolute;
  content: url(../../assets/images/Dot2.webp);
  top: 50%;
  left: 0;
  width: 189px;
  height: 236px;
} */

.main-poster .desc {
  position: absolute;
  left: 113px;
  top: 30%;
  width: 640px;
}
.main-poster .desc .title {
  font-size: 30px;
  width: 455px;
  color: #fff;
  line-height: 45px;
  font-family: "Inter";
  font-weight: 600;
  padding-top: 35px;
}
.main-poster .desc img {
  width: 400px;
  height: 80px;
  object-fit: contain;
}
.main-poster .desc .list {
  display: flex;
  align-items: flex-start;
  gap: 70px;
  margin-top: 30px;
}
.main-poster .desc .list li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.main-poster .desc .list .name {
  color: #565656;
  font-size: 19px;
  line-height: 31px;
  font-weight: 400;
  font-family: "DM Sans";
}
.main-poster .desc .list .num {
  font-size: 39px;
  line-height: 59px;
  font-weight: 700;
  color: #fff;
}
.main-poster .desc .list .num::after {
  content: attr(unit);
}
.about-section .about-content {
  background: url(../../assets/images/Vector4.webp) no-repeat top right;
  background-size: contain;
  padding: 80px 0;
}

.video-main {
  width: 100%;
  min-height: 700px;
}
.video-main iframe {
  width: 70%;
  height: 70%;
  margin: 0 auto;
}

.about-section .title {
  font-size: 38px;
  font-weight: 900;
  line-height: 57px;
  color: #66c16d;
  background-image: -webkit-linear-gradient(180deg, #22852a 53%, #66c16d 80%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  margin-bottom: 25px;
  text-align: center;
  z-index: 1;
  position: relative;
}

.about-section p {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  line-height: 35px;
  margin: 15px 0;
}
.about-section p > strong {
  color: #2bbc28;
  font-weight: 700;
}
.about-section .button {
  width: 200px;
  height: 62px;
  border-radius: 10px;
  background: #1e6424;
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto 0;
  transition: all 0.5s ease;
  box-shadow: rgba(30, 100, 36, 0.19) 0px 48px 150px 0px;
}
.about-section .button:hover {
  background: #22852a;
}

.services-section {
  padding-top: 100px;
  background: url(../../assets/images/back2.webp) no-repeat right bottom;
  position: relative;
}
.services-section .left-col {
  width: 661px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.services-section .left-col .titr {
  font-size: 25px;
  font-weight: 400;
  color: #000000;
  line-height: 40px;
  margin-bottom: 15px;
}
.services-section .left-col .box {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.services-section .desc .caption {
  font-weight: 700;
  font-size: 22px;
  color: #000000;
  padding-right: 5px;
}
.services-section::before {
  position: absolute;
  content: "";
  width: 1000px;
  height: 1000px;
  background: radial-gradient(
    ellipse at center,
    rgba(1, 225, 210, 1) 0%,
    rgba(1, 210, 67, 0.17) 100%
  );
  right: 0;
  bottom: 0;
  opacity: 0.1;
  filter: blur(1.17rem);
}
.services-section p {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 15px;
}
.services-section strong {
  color: #2bbc28;
  font-weight: 700;
}
.services-section .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 95px;
  padding-bottom: 120px;
}
.services-section .right-col {
  width: 668px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.services-section .right-col .box {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.services-section .imgbox {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.services-section .imgbox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.partners-section {
  height: 372px;
  width: 100%;
  background: url(../../assets/images/back3.webp) no-repeat top left, #000;
  background-size: 100%;
  padding: 50px;
}
.partners-section img {
  height: 40px;
}
.partners-section .title {
  text-transform: uppercase;
  font-size: 45px;
  line-height: 67px;
  font-weight: 700;
  color: #ffff;
  background-image: -webkit-linear-gradient(0deg, #ffff 18%, #06132d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 85px;
}
.partners-section .swiper .swiper-wrapper {
  transition-timing-function: linear;
}
.partners-section .swiper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.partners-section .swiper .swiper-slide a {
  height: 50px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.partners-section .swiper img {
  filter: brightness(0) invert(1);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media only screen and (min-width: 0px) and (max-width: 479px) {
  .container {
    width: 96%;
  }
  .main-poster {
    display: flex;
    padding-bottom: 45px;
  }
  .main-poster .img-poster::before {
    top: -100%;
    display: none;
  }
  .main-poster .img-poster::after {
    display: none;
  }

  .main-poster .desc {
    width: 100%;
    position: static;
    background: #000800;
    padding: 20px 15px 0;
    order: -1;
  }
  .main-poster .desc .title {
    font-size: 23px;
    line-height: 34px;
    padding-top: 10px;
    width: 100%;
  }
  .main-poster .desc > img {
    display: none;
  }
  .main-poster .desc .list {
    column-gap: 30px;
    margin-top: 10px;
  }
  .main-poster .desc .list .num {
    font-size: 33px;
  }
  .main-poster::after {
    height: 125px;
    bottom: 0;
  }
  .video-main {
    min-height: 210px;
  }
  .about-section p {
    font-size: 16px;
    line-height: 28px;
  }
  .about-section .button {
    height: auto;
    padding: 7px 25px;
    width: fit-content;
    font-size: 18px;
  }
  .about-section .about-content {
    padding: 40px 0;
  }
  .about-section .title {
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 15px;
  }
  .partners-section .title {
    font-size: 35px;
    line-height: 50px;
  }
  .partners-section {
    padding: 20px;
    background-size: 100% 100%;
    height: auto;
  }
  .partners-section .title {
    margin-bottom: 30px;
  }
  .partners-section .partners {
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .partners-section .partners img {
    width: 140px;
  }
  .services-section {
    padding-top: 35px;
    background-position: bottom right;
  }
  .services-section p {
    font-size: 17px;
    line-height: 30px;
  }
  .services-section .desc .caption {
    font-size: 17px;
  }
  .services-section .wrapper {
    gap: 20px;
    padding-bottom: 80px;
    flex-wrap: wrap;
  }
  .services-section::before {
    display: none;
  }
  .services-section .left-col {
    width: 100%;
    row-gap: 20px;
  }
  .services-section .left-col .box {
    gap: 15px;
  }
}
/*480 up to 719*/
@media only screen and (min-width: 480px) and (max-width: 719px) {
  .container {
    width: 460px;
  }
  .main-poster {
    display: flex;
    padding-bottom: 25px;
  }

  .main-poster .img-poster::after {
    display: none;
  }

  .main-poster .desc {
    width: 100%;
    position: static;
    background: #000800;
    padding: 20px 15px 0;
    order: -1;
  }
  .main-poster .desc .title {
    font-size: 23px;
    line-height: 34px;
    padding-top: 10px;
    width: 100%;
    text-align: center;
  }
  .main-poster .desc > img {
    display: none;
  }
  .main-poster .desc .list {
    column-gap: 30px;
    margin-top: 10px;
  }
  .main-poster .desc .list .num {
    font-size: 33px;
  }
  .main-poster::after {
    height: 148px;
    bottom: 0;
  }

  .video-main {
    min-height: 250px;
  }
  .about-section p {
    font-size: 19px;
    line-height: 35px;
  }
  .about-section .button {
    height: 60px;
  }
  .about-section .about-content {
    padding: 60px 0;
  }
  .about-section .title {
    font-size: 27px;
    line-height: 40px;
  }
  .partners-section {
    padding: 30px;
    background-size: 100% 100%;
    height: auto;
  }
  .partners-section .title {
    margin-bottom: 40px;
    font-size: 38px;
  }
  .partners-section .partners {
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .partners-section .partners img {
    width: 126px;
    height: auto;
  }
  .services-section {
    padding-top: 45px;
    background-position: bottom right;
  }
  .services-section p {
    font-size: 19px;
    line-height: 30px;
  }
  .services-section .desc .caption {
    font-size: 22px;
  }
  .services-section .wrapper {
    gap: 45px;
    padding-bottom: 80px;
    flex-wrap: wrap;
  }
  .services-section::before {
    display: none;
  }
  .services-section .left-col {
    width: 100%;
    row-gap: 20px;
  }
  .services-section .left-col .box {
    gap: 15px;
  }
}
/*720 up to 1000*/
@media only screen and (min-width: 720px) and (max-width: 1000px) {
  .container {
    width: 700px;
  }
  .main-poster .desc {
    left: 35px;
    width: 250px;
    top: 25%;
  }
  .main-poster .desc .title {
    font-size: 26px;
    line-height: 34px;
    padding-top: 10px;
    width: 300px;
  }
  .main-poster .desc .list {
    column-gap: 30px;
  }
  .main-poster .desc .list .num {
    font-size: 33px;
  }
  .main-poster::after {
    height: 283px;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .video-main {
    min-height: 380px;
  }
  .about-section p {
    font-size: 20px;
  }
  .about-section .button {
    height: 60px;
  }
  .about-section .about-content {
    padding: 40px 0;
  }
  .about-section .title {
    font-size: 30px;
    line-height: 45px;
  }
  .partners-section {
    padding: 30px;
    height: auto;
    background-size: 100% 100%;
  }
  .partners-section .title {
    margin-bottom: 40px;
  }
  .partners-section .partners {
    column-gap: 20px;
  }
  .services-section {
    padding-top: 65px;
  }
  .services-section p {
    font-size: 19px;
    line-height: 30px;
  }
  .services-section .desc .caption {
    font-size: 22px;
  }
  .services-section .wrapper {
    gap: 45px;
    padding-bottom: 80px;
  }
  .services-section::before {
    width: 500px;
    height: 500px;
  }
}
/*1001 up to 1249*/
@media only screen and (min-width: 1001px) and (max-width: 1249px) {
  .container {
    width: 980px;
  }

  .main-poster .desc {
    left: 35px;
    width: 370px;
  }
  .main-poster .desc .title {
    font-size: 36px;
  }
  .main-poster .desc .list {
    column-gap: 40px;
  }
  .main-poster::after {
    height: 378px;
    bottom: -25px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .video-main {
    min-height: 450px;
  }
  .about-section p {
    font-size: 20px;
  }
  .about-section .button {
    height: 60px;
  }
  .about-section .about-content {
    padding: 60px 0;
  }
  .partners-section {
    padding: 30px;
    height: auto;
    background-size: 100% 100%;
  }
  .partners-section .title {
    margin-bottom: 40px;
  }
  .services-section {
    padding-top: 65px;
  }
  .services-section p {
    font-size: 21px;
  }
  .services-section .wrapper {
    gap: 65px;
    padding-bottom: 80px;
  }
}
/*laptop*/
@media only screen and (min-width: 1250px) and (max-width: 1366px) {
  .container {
    width: 1200px;
  }
  .main-poster .desc {
    left: 70px;
  }
}
/*1250 up to 1400*/
@media only screen and (min-width: 1367px) and (max-width: 1450px) {
  .container {
    width: 1335px;
  }
}
@media only screen and (min-width: 1451px) and (max-width: 1600px) {
  .container {
    width: 1335px;
  }
}
/*1750 to up*/
@media only screen and (min-width: 1601px) {
  .container {
    width: 1406px;
  }
}
