.faq-page {
  margin-top: 125px;
  min-height: 500px;
  padding-top: 50px;
}
.faq-page .title {
  text-align: center;
  color: #66c16d;
  background-image: -webkit-linear-gradient(180deg, #22852a 53%, #66c16d 80%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 40px;
}
.accordion {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin-bottom: 40px;
}

.accordion--item {
  width: 100%;
  color: #000;
  overflow: hidden;
  cursor: pointer;
  max-height: 50px;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}
.accordion--item.collapsed {
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 10px;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-bottom: none;
  position: relative;
}

.accordion--header .title--accordion {
  transition: 0.2s ease-in-out;
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  padding-right: 15px;
}

.accordion--header i {
  transition: 0.2s ease-in-out;
  transform-origin: center;
  position: absolute;
  right: 0;
}
.accordion--item.collapsed i {
  transform: rotate(180deg);
}

.accordion--header:hover .title--accordion {
  color: #22852a !important;
}

.accordion--content {
  color: #000;
  padding: 20px;
  border: 1px solid #dee2e6;
}
.tos-item {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 15px;
}
.tos-item p {
  width: 95%;
  margin: 0 auto;
  line-height: 1.5;
}
.tos-item h2 {
  font-weight: 700;
  margin-bottom: 15px;
  /* margin: 0 auto; */
}

.tos-container {
  background-color: #f6f6f685;

  width: 80%;
  margin: 60px auto;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 15px rgba(235, 234, 234, 0.545);
}

@media only screen and (min-width: 0px) and (max-width: 479px) {
  .tos-container {
    width: 95%;
  }
  .faq-page {
    margin-top: 0;
    padding-top: 30px;
  }
  .faq-page .title {
    font-size: 21px;
  }
  .accordion--header .title--accordion {
    font-size: 12px;
  }
  .accordion--item {
    max-height: 45px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 719px) {
  .faq-page {
    margin-top: 0;
    padding-top: 30px;
  }
  .faq-page .title {
    font-size: 27px;
  }
  .accordion--header .title--accordion {
    font-size: 12px;
  }
  .accordion--item {
    max-height: 43px;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1000px) {
  .faq-page {
    margin-top: 50px;
    padding-top: 70px;
  }
  .faq-page .title {
    font-size: 27px;
  }
  .accordion--header .title--accordion {
    font-size: 12px;
  }
  .accordion--item {
    max-height: 43px;
  }
}
