.about-page{
    margin-top: 125px;
    min-height: 500px;
}
.about-page .part{
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    column-gap:50px;
    padding-top:50px;
    flex-wrap:nowrap;
}
.about-page .part2{
    flex-direction: row-reverse;
}
.about-page .title{
    color: #66c16d;
    font-weight: bold;
    line-height: 65px;
    font-size: 40px;
    background: -webkit-linear-gradient(#22852a, #22852a);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.about-page .img--about{
    width:400px;
}
.about-page .info{
    width: calc(100% - 450px);
    color: #495057;
    font-size: 16px;
    line-height: 27px
}
.title2{
    font-size: 30px;
    color: #22852a;
    font-weight: bold;
    line-height: 45px;
    padding-bottom: 20px;
}

@media only screen and (min-width: 0px) and (max-width: 479px) {
    .about-page {
        margin-top: 0;
    }
    .about-page .container > div{
        flex-wrap: wrap;
    }
    .about-page .img--about {
        width: 100%;
    }
    .about-page .info {
        width: 100%;
    }
}
/*480 up to 719*/
@media only screen and (min-width: 480px) and (max-width: 719px) {
    .about-page {
        margin-top: 0;
    }
    .about-page .container > div{
        flex-wrap: wrap;
    }
    .about-page .img--about {
        width: 100%;
    }
    .about-page .info {
        width: 100%;
    }
}
@media only screen and (min-width: 720px) and (max-width: 1000px) {
    .about-page {
        margin-top: 70px;
    }
    .about-page .img--about {
        width: 300px;
    }
    .about-page .info {
        width: calc(100% - 350px);
    }
}
